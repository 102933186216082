





































































































import { Component, Mixins, Ref, Watch } from "vue-property-decorator";
import { VuetifyCalendar, VuetifyCalendarEvent } from "@/types/vuetify.type";
import moment from "moment";
import { RoleMixin } from "@/mixins/role-mixin";
import { LeaveRequestModule } from "@/store/modules/leave-request-module";
import { LeaveRequestAllInterface } from "@/types/leave-request.type";
import { DurationHelperMixins } from "@/mixins/duration-helper-mixins";

type CalendarEvent = VuetifyCalendarEvent;

@Component
export default class LeaveRequestCalendar extends Mixins(RoleMixin) {
  private focus = "";
  private type = "month";
  private typeToLabel = {
    month: "Month",
    week: "Week",
    day: "Day",
    "4day": "4 Days",
  };
  private selectedEvent: CalendarEvent = {} as CalendarEvent;
  private selectedElement: EventTarget | null = null;
  private selectedOpen = false;
  private eventToolbarMenu: boolean = false;
  private updateEventKey: number = 0;
  private colorRandomizer: any = {};
  private colorRandomSet: any = {};

  @Ref()
  private readonly calendar!: VuetifyCalendar;

  private mounted() {
    this.calendar.checkChange();
  }

  private async created() {
    const month = moment();
    const startOfMonth = month.startOf("month").format("YYYY-MM-DD");
    const endOfMonth = month.endOf("month").format("YYYY-MM-DD");
    await LeaveRequestModule.getAllLeaveRequestByMonth({ startDate: startOfMonth, endDate: endOfMonth });
  }

  private get leaveRequests(): LeaveRequestAllInterface[] {
    return LeaveRequestModule.allPublicLeaveRequest;
  }

  private setToday() {
    this.focus = "";
    this.$nextTick(() => {
      this.onCalendarTitleChange(this.calendar.title);
    });
  }

  private formatEventName(leaveRequest: LeaveRequestAllInterface) {
    const mix = new DurationHelperMixins();
    return `${leaveRequest.createdBy.firstName} - ${mix.formatLeaveDuration(leaveRequest.duration)}`;
  }

  private get events(): any[] {
    return this.leaveRequests.map((value) => {
      let start: string | Date = value.startDate;
      let end: string | Date = value.endDate;
      const timed = false;
      if (value.duration === "half-morning") {
        start = moment(value.startDate).set("hours", 9).toDate();
        end = moment(value.endDate).set("hours", 12).toDate();
      } else if (value.duration === "half-afternoon") {
        start = moment(value.startDate).set("hours", 13).toDate();
        end = moment(value.endDate).set("hours", 18).toDate();
      }

      return {
        start,
        end,
        timed,
        name: this.formatEventName(value),
        ...value,
      };
    });
  }

  @Watch("events")
  private onEventsChange() {
    this.updateEventKey++;
  }

  private getRandomColor(): string {
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += Math.floor(Math.random() * 10);
    }

    if (this.colorRandomSet[color]) {
      return this.getRandomColor();
    }

    this.colorRandomSet[color] = 1;
    return color;
  }

  private getEventColor(event: LeaveRequestAllInterface): string {
    if (event && event.createdBy) {
      if (!this.colorRandomizer[event.createdBy.userDisplay]) {
        this.colorRandomizer[event.createdBy.userDisplay] = this.getRandomColor();
      }
      return this.colorRandomizer[event.createdBy.userDisplay];
    }
    return "";
  }

  private updateMonth(type: string) {
    if (type === "next") {
      this.calendar.next();
    } else {
      this.calendar.prev();
    }
    this.$nextTick(() => {
      this.onCalendarTitleChange(this.calendar.title);
    });
  }

  private async onCalendarTitleChange(newTitle: string) {
    const month = moment(newTitle, "MMMM YYYY");
    const startOfMonth = month.startOf("month").format("YYYY-MM-DD");
    const endOfMonth = month.endOf("month").format("YYYY-MM-DD");
    await LeaveRequestModule.getAllLeaveRequestByMonth({ startDate: startOfMonth, endDate: endOfMonth });
  }

  private viewDay({ date }: any) {
    this.focus = date;
    this.type = "day";
  }
}
